import { CommonModule } from '@angular/common';
import {
  Component, computed, input, model,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { FieldState } from '../../../models/field.model';
import { InlineSvgComponent } from '../../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-date-field',
  imports: [ CommonModule, FormsModule, InlineSvgComponent ],
  templateUrl: './date-field.component.html',
  styleUrl: './date-field.component.scss',
})
export class DateFieldComponent {
  fieldState = FieldState;
  name = input<string>(`ui-currency-field-${(Math.round((Math.random() * 100000000)).toString(16))}`);
  value = model<string | undefined>('');
  state = input<FieldState>(FieldState.Default);
  alert = input<string>();

  icon = computed(() => {
    switch (this.state()) {
      case FieldState.Success:
      case FieldState.Info:
        return 'success';
      case FieldState.Invalid:
        return 'error';
      default:
        return '';
    }
  });
}
