import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';

import { InlineSvgComponent } from '../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-button-alert',
  imports: [ CommonModule, InlineSvgComponent ],
  templateUrl: './button-alert.component.html',
  styleUrl: './button-alert.component.scss',
})
export class ButtonAlertComponent {
  open = input<boolean>(false);
  notifications = input<number>(0);

  doubleDigits = computed(() => this.notifications() > 9);
}
