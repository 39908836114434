import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  input,
  model,
  OnChanges,
  output,
  SimpleChanges,
} from '@angular/core';

import { TranslocoModule } from '@jsverse/transloco';
import { BehaviorSubject } from 'rxjs';

import {
  Column, FilterOptions, FilterQuery, TableFilter,
} from '../../../models/tabel.model';
import { CheckboxComponent } from '../../checkbox/checkbox.component';
import { InlineSvgComponent } from '../../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-table-filter',
  standalone: true,
  imports: [ CommonModule, TranslocoModule, InlineSvgComponent, CheckboxComponent ],
  templateUrl: './table-filter.component.html',
  styleUrl: './table-filter.component.scss',
  providers: [],
})
export class TableFilterComponent implements OnChanges {
  config = model<TableFilter<Column>>();
  label = input<string | undefined>();
  clearFilter = input<boolean>(false);

  filterQuery = output<FilterQuery | undefined>();
  sortQuery = output<string>();
  checkAll = output<boolean | string>();

  filterQuerySubject = new BehaviorSubject<string | undefined>(undefined);

  cssStyle = computed(() => {
    const config = this.config();
    const grid = config?.grid || `repeat(${config?.columns}, 1fr)`;

    return {
      'grid-template-columns': `min-content ${grid} min-content`,
    };
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['label'].currentValue) {
      this.updateFilterLabel(changes['label'].currentValue);
    } else {
      this.updateTableConfig(undefined, undefined, true);
    }
  }

  onClickCheckAll(event: boolean | string): void {
    this.checkAll.emit(event);
  }

  onClickFilter(column: Column, value?: any): void {
    this.updateTableConfig(column, (filter) => ({
      ...filter,
      selectedValue: value,
      isOpen: false,
    }));

    this.updateQuery();
  }

  onClickSort(column: Column): void {
    this.updateTableConfig(column, (filter) => ({
      ...filter,
      sortAscending: !filter?.sortAscending,
    }));

    this.filterQuery.emit({
      query: this.filterQuerySubject.getValue(),
      sortAscending: column.filter?.sortAscending,
      sortBy: column.sortBy,
    });
  }

  onOpenFilter(column: Column): void {
    this.updateTableConfig(column, (filter) => ({
      ...filter,
      isOpen: !filter?.isOpen,
    }));
  }

  private updateTableConfig(
    column?: Column,
    updater?: (filter: FilterOptions) => FilterOptions,
    reset?: boolean
  ): void {
    this.config.update((currentConfig) => {
      if (!currentConfig || !currentConfig.options) {
        return currentConfig;
      }

      return {
        ...currentConfig,
        options: currentConfig.options.map((option) => {
          if (reset) {
            return {
              ...option,
              filter: { selectedValue: undefined },
            };
          }

          if (option === column) {
            return {
              ...option,
              filter: updater!(option.filter!),
            };
          }

          return option;
        }),
      };
    });
  }

  private updateQuery(): void {
    const query = this.config()
      ?.options.map((option) =>
        option.filter?.selectedValue ? `${option.sortBy}:${option.filter.selectedValue}` : null
      )
      .filter(Boolean)
      .join(';');

    this.filterQuery.emit({ query });
    this.filterQuerySubject.next(query);
  }

  private updateFilterLabel(label: string): void {
    if (label.includes(';')) {
      return;
    }

    const splitLabel = label.split(':');
    const column = this.config()?.options.find((column) => column.sortBy === splitLabel![0]);

    this.updateTableConfig(column as Column, (filter) => ({
      ...filter,
      selectedValue: splitLabel[1],
    }));
  }
}
