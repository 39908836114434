import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { InlineSvgComponent } from '../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-logo',
  imports: [ CommonModule, InlineSvgComponent ],
  templateUrl: './logo.component.html',
  styleUrl: './logo.component.scss',
})
export class LogoComponent {
  @Input() invert = false;
  @Input() vertical = false;
}
