import {
  OverlayRef,
  Overlay,
  OverlayPositionBuilder,
  ConnectionPositionPair,
  ConnectedPosition,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef, Directive, ElementRef, HostListener, input,
} from '@angular/core';

import { TooltipComponent } from './tooltip.component';

@Directive({
  selector: '[uiTooltip]',
})
export class TooltipDirective {
  tooltipText = input.required<string>();
  tooltipPosition = input<'top' | 'bottom' | 'start' | 'end'>('top');

  private overlayRef!: OverlayRef;
  private tooltipRef!: ComponentRef<TooltipComponent>;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
  ) {}

  @HostListener('mouseenter') onMouseEnter() {
    this.show();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hide();
  }

  createTooltip() {
    let position = {} as ConnectedPosition;

    if (this.tooltipPosition() === 'bottom') {
      position = {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        offsetY: 8,
      };
    } else if (this.tooltipPosition() === 'end') {
      position = {
        originX: 'end',
        originY: 'center',
        overlayX: 'start',
        overlayY: 'center',
        offsetX: 8,
      };
    } else if (this.tooltipPosition() === 'top') {
      position = {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        offsetY: -8,
      };
    } else {
      position = {
        originX: 'start',
        originY: 'center',
        overlayX: 'end',
        overlayY: 'center',
        offsetX: -8,
      };
    }

    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        { ...position },
        // if there is no space, it automatically adjusts.
        new ConnectionPositionPair(
          // bottom
          {
            originX: 'center',
            originY: 'bottom',
          },
          {
            overlayX: 'center',
            overlayY: 'top',
          },
          0,
          8,
        ),
        new ConnectionPositionPair(
          // top
          {
            originX: 'center',
            originY: 'top',
          },
          {
            overlayX: 'center',
            overlayY: 'bottom',
          },
          0,
          -8,
        ),
        new ConnectionPositionPair(
          // end
          {
            originX: 'end',
            originY: 'center',
          },
          {
            overlayX: 'start',
            overlayY: 'center',
          },
          8,
          0,
        ),
        new ConnectionPositionPair(
          // start
          {
            originX: 'start',
            originY: 'center',
          },
          {
            overlayX: 'end',
            overlayY: 'center',
          },
          -8,
          0,
        ),
      ]);

    this.overlayRef = this.overlay.create({ positionStrategy });
    this.tooltipRef = this.overlayRef.attach(new ComponentPortal(TooltipComponent));
  }

  private show() {
    this.createTooltip();
    this.tooltipRef.instance.text = this.tooltipText;
    this.tooltipRef.instance.position = this.tooltipPosition;
  }

  private hide() {
    if (this.tooltipRef) {
      this.tooltipRef.destroy();
      this.overlayRef.detach();
    }
  }
}
