/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import {
  Component, computed, input, model,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { InlineSvgComponent } from '../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-radio-button',
  imports: [ CommonModule, InlineSvgComponent, FormsModule ],
  templateUrl: './radio-button.component.html',
  styleUrl: './radio-button.component.scss',
})
export class RadioButtonComponent<T> {
  name = input<string>(`ui-radio-${(Math.round((Math.random() * 100000000)).toString(16))}`);
  option = input<T>();
  value = model<T>();
  iconName = computed(() => {
    return this.isChecked() ? 'radio-true' : 'radio-false';
  });

  isChecked() {
    return this.value() === this.option();
  }

  toggleChecked() {
    this.value.set(this.option());
  }
}
