import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { AuthService, ExternalLoginComponent } from '@instaclause-webapp/shared-core';
import {
  ButtonComponent,
  CheckboxComponent,
  EmailFieldComponent,
  PasswordFieldComponent,
  ToastService,
  ToastType,
} from '@instaclause-webapp/shared-ui';
import { TranslocoModule } from '@jsverse/transloco';
import { firstValueFrom, Subscription } from 'rxjs';

import { MenuComponent } from '../../ui/menu/menu.component';

@Component({
  selector: 'app-login',
  imports: [
    CommonModule,
    MenuComponent,
    RouterModule,
    TranslocoModule,
    EmailFieldComponent,
    PasswordFieldComponent,
    CheckboxComponent,
    ButtonComponent,
    ExternalLoginComponent,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit, OnDestroy {
  loginModel = {
    email: '',
    password: '',
  };
  rememberMe = true;

  private subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private toastService: ToastService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.subscription.add(
      this.authService.user$.subscribe((user) => {
        console.log('{ login } - user: ', user);

        if (user) {
          this.router.navigate([ './' ]);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async login() {
    if (this.loginModel.email && this.loginModel.password) {
      const user = await firstValueFrom(
        this.authService.login(this.loginModel.email, this.loginModel.password, this.rememberMe)
      );

      if (!user) {
        this.toastService.showToast({
          type: ToastType.Error,
          text: 'Something went wrong. Try again.',
          duration: 5,
        });
      }
    }
  }
}
