<div class="grid column-{{ column() }}">
  <div class="grid-title">
    <div class="grid-title-content" [class.is-sticky]="sticky()">
      <ng-content select="[title]"></ng-content>
    </div>
  </div>
  <div class="grid">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
