import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasValue',
})
export class HasValuePipe<T> implements PipeTransform {
  transform(params: T[], value: T): boolean {
    return params.includes(value);
  }
}
