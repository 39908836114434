import { CdkOverlayOrigin, OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  Component, OnInit, ViewChild, OnDestroy, inject, signal, effect,
} from '@angular/core';
import { RouterModule } from '@angular/router';

import {
  AuthService, Breadcrumb, PageService, User,
} from '@instaclause-webapp/shared-core';
import { InitialsPipe, LogoComponent, TooltipDirective } from '@instaclause-webapp/shared-ui';
import { ButtonAlertComponent } from '@instaclause-webapp/shared-ui';
import { InlineSvgComponent } from '@instaclause-webapp/shared-ui';
import { TranslocoModule } from '@jsverse/transloco';
import { Subscription } from 'rxjs';

import { LanguageComponent } from '../language/language.component';
import { UserMenuComponent } from '../user-menu/user-menu.component';

@Component({
  selector: 'app-menu',
  imports: [
    CommonModule,
    TranslocoModule,
    LogoComponent,
    LanguageComponent,
    InlineSvgComponent,
    ButtonAlertComponent,
    UserMenuComponent,
    OverlayModule,
    RouterModule,
    TooltipDirective,
    InitialsPipe,
  ],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
  providers: [],
})
export class MenuComponent implements OnInit, OnDestroy {
  @ViewChild(UserMenuComponent) userMenu!: UserMenuComponent;
  user!: User | null;
  alertsOpen = false;
  userMenuOpen = false;

  breadcrumb = signal<Breadcrumb[]>([]);
  contractName = signal<string | undefined>(undefined);
  currentPage = signal<string>('');

  private authService = inject(AuthService);
  private pageService = inject(PageService);
  private subscription = new Subscription();

  private trackRouterEvent = effect(
    () => {
      const routerSubscription = this.pageService.breadcrumbs$.subscribe((response) => {
        this.currentPage.set(this.pageService.currentPath$.getValue());
        this.breadcrumb.set(response);
      });

      this.subscription.add(routerSubscription);
    },
    { allowSignalWrites: true }
  );

  ngOnInit(): void {
    this.subscription.add(this.authService.user$.subscribe((user) => (this.user = user)));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  triggerMenu(event: MouseEvent, trigger: CdkOverlayOrigin): void {
    console.log('triggerMenu', trigger);
    this.userMenuOpen = !this.userMenuOpen;

    if (this.userMenuOpen) {
      event.stopPropagation();
      this.userMenu.openMenu();
      // this.userMenu.openMenu(trigger);
    } else {
      this.userMenu.closeMenu();
    }
  }

  onCloseMenu() {
    this.userMenuOpen = false;
  }
}
