<div class="grid">
  <aside class="grid-sidebar color-{{ sidebarBackground() }}">
    <div class="grid-sidebar-content" [class.is-fixed]="fixed()">
      <div class="grid-sidebar-content-item">
        <ng-content select="[sidebar]"></ng-content>
      </div>
    </div>
  </aside>
  <main class="grid-content">
    <ng-content></ng-content>
  </main>
</div>
