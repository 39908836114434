import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

import { TabItemModel } from '../../models/tab.model';
import { InlineSvgComponent } from '../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-tabs',
  imports: [ CommonModule, InlineSvgComponent ],
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss',
})
export class TabsComponent {
  icon = input<boolean>(false);
  tabs = input.required<TabItemModel[]>();


  selectTab() {
    console.log('tab selected');
  }

  checkTab() {
    console.log('tab checked');
  }
}
