import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'ui-page',
  imports: [ CommonModule ],
  templateUrl: './page.component.html',
  styleUrl: './page.component.scss',
  providers: [],
})
export class PageComponent {
  fixed = input<boolean>(false);
  sidebarBackground = input<'white' | 'gray'>();
}
