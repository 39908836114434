import { CommonModule } from '@angular/common';
import {
  Component, HostListener, OnInit, signal, input,
  inject,
} from '@angular/core';
import { RouterModule } from '@angular/router';

import { LanguageService, Languages } from '@instaclause-webapp/shared-core';
import { ButtonComponent } from '@instaclause-webapp/shared-ui';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-ui-language',
  imports: [ CommonModule, RouterModule, ButtonComponent, TranslocoModule ],
  templateUrl: './language.component.html',
  styleUrl: './language.component.scss',
})
export class LanguageComponent implements OnInit {
  pagePosition = input.required<string>();
  languages = signal<Languages[]>([]);
  currentLanguage = signal<string>('en');
  isOpenLanguages = signal<boolean>(false);

  protected languageService = inject(LanguageService);

  @HostListener('document:click', [ '$event' ])
  onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    const isClickedInsideList = targetElement.classList.contains('language-list');
    const isClickedInsideWrapper = targetElement.closest('.language-wrapper');

    if (!isClickedInsideList && !isClickedInsideWrapper && this.isOpenLanguages()) {
      this.isOpenLanguages.set(false);
    }
  }

  ngOnInit(): void {
    this.languages.set(this.languageService.getLanguages());
  }

  onChangeLanguage(language: Languages): void {
    this.languageService.changeLanguage(language);
    this.currentLanguage.set(language);
    this.isOpenLanguages.set(false);
  }

  onClickOpenLanguagePicker() {
    this.isOpenLanguages.set(!this.isOpenLanguages());
  }
}
