import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';

import { InlineSvgComponent } from '../inline-svg/inline-svg.component';

export type ButtonColors =
  | 'primaryOnLight'
  | 'primaryOnDark'
  | 'secondaryOnLight'
  | 'secondaryOnDark'
  | 'ghostOnLight'
  | 'ghostOnDark'
  | 'contrastOnLight'
  | 'contrastOnDark'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';

export type ButtonSizes = 'smaller' | 'small' | 'medium' | 'large';

@Component({
  selector: 'ui-button',
  imports: [ CommonModule, InlineSvgComponent ],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {

  /** Specifies the color theme of the button.  */
  color = input<ButtonColors>('primaryOnLight');
  /** Defines the size of the button.  */
  size = input<ButtonSizes>('medium');
  /** Determines if the button is disabled.  */
  disabled = input<boolean>(false);
  /** Allows the selection of an optional icon.  */
  icon = input<string>();
  /** Indicates whether the button content is centered.  */
  centered = input<boolean>(false);
  /** Flips the icon position from left to right within the button.  */
  inverted = input<boolean>(false);

  cssClasses = computed(() => {
    return {
      'is-centered': this.centered(),
      'is-inverted': this.inverted(),
      disabled: this.disabled(),
      [this.size()]: true,
      [this.color()]: true,
    };
  });
}
