<div class="language-wrapper" [class.isOpen]="isOpenLanguages()">
  <div class="language-button">
    <ui-button
      color="ghostOnLight"
      icon="language"
      (click)="onClickOpenLanguagePicker()">
      {{ 'LANGUAGES.' + currentLanguage().toUpperCase() | transloco }}
    </ui-button>
  </div>

  @if(isOpenLanguages()){
  <ul
    class="language-list"
    [class.bottom]="pagePosition() === 'bottom'"
    [class.top]="pagePosition() === 'top'">
    @for (language of languages(); track language) {
    <li
      class="language-list-item"
      [class.top]="pagePosition() === 'top'"
      (click)="onChangeLanguage(language)">
      {{ 'LANGUAGES.' + language.toUpperCase() | transloco }}
    </li>
    }
  </ul>
  }
</div>
