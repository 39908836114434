<thead class="container" [ngStyle]="cssStyle()">
  <ui-checkbox
    class="container-checkbox"
    [style.visibility]="config()?.checkbox ? 'visible' : 'hidden'"
    (valueChange)="onClickCheckAll($event)"
    ></ui-checkbox>

  @for(option of config()?.options; track $index ) {

  <div class="container-column">
    @if (!option.filter?.selectedValue) {
    <span (mouseenter)="onOpenFilter(option)" class="container-column-text">
      {{ 'CONTRACT.LIST.HEADER.' + option.label | transloco }}</span
    >

    @if (config()?.sorting) {
    <ui-inline-svg
      class="container-column-icon"
      (click)="onClickSort(option)"
      [name]="
        !option.filter?.sortAscending ? 'tooltip-arrow-top' : 'tooltip-arrow-bottom'
      "></ui-inline-svg>
    } } @else {
    <div class="container-column-value" (click)="onClickFilter(option, undefined)">
      <span class="container-column-value-text">{{ option.filter?.selectedValue }}</span>
      <ui-inline-svg class="container-column-value-close" name="close"></ui-inline-svg>
    </div>

    } @if (option.filter?.isOpen && option.values.length) {
    <div class="container-column-filter" (mouseleave)="onOpenFilter(option)">
      <div class="container-column-filter-dropdown">
        <hr class="container-column-filter-dropdown-separator" />
        @for (item of option.values | slice:0:8; track $index) {
        <div
          class="container-column-filter-dropdown-list"
          (click)="onClickFilter(option, item.value)">
          {{ item.text }}
        </div>
        }
      </div>
    </div>
    }
  </div>
  }
</thead>
