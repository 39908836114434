import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'ui-section',
  imports: [ CommonModule ],
  templateUrl: './section.component.html',
  styleUrl: './section.component.scss',
  providers: [],
})
export class SectionComponent {
  column = input<1 | 2>(2);
  sticky = input<boolean>(false);
}
