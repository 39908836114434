import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { TranslocoModule } from '@jsverse/transloco';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ENVIRONMENT } from '../../environments/environment.token';
import { CustomerInfoResponse } from '../../models/customer.model';
import { AuthService } from '../auth.service';

declare global {
  interface Window {
    google: any;
  }
}

@Component({
  selector: 'shared-core-external-login',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
  ],
  templateUrl: './external-login.component.html',
  styleUrls: [ './external-login.component.scss' ],
})
export class ExternalLoginComponent implements OnInit, OnDestroy {
  @ViewChild('googleButtonDiv') googleButtonDiv!: ElementRef;

  private subscription = new Subscription();
  private environment = inject(ENVIRONMENT);
  private authService = inject(AuthService);
  private route = inject(ActivatedRoute);
  private tenantId = signal<string | null | undefined>(null);


  constructor() {
    this.initGoogleSignIn();
  }

  ngOnInit() {
    this.subscription.add(
      this.route.queryParamMap.pipe(
        switchMap((params: ParamMap) => {
          const param = params.get('customer');

          return this.authService.getCustomerInfo(param);
        }
        )
      ).subscribe((result) => {
        this.handleCustomerInfoResponse(result);
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private initGoogleSignIn() {
    window.google.accounts.id.initialize({
      client_id: this.environment.googleSignInClientId,
      context: 'signin',
      ux_mode: 'popup',
      auto_prompt: false,
      callback: this.handleGoogleResponse.bind(this),
    });
  }

  private handleCustomerInfoResponse(customerInfoResponse: CustomerInfoResponse | null) {
    if (customerInfoResponse) {
      this.tenantId.set(customerInfoResponse.customerInfo?.tenantId);
      const providers = customerInfoResponse.customerInfo?.providers;
      if (providers && providers.indexOf('Google') > -1) {
        this.renderGoogleSignIn();
      }
    }
  }

  private renderGoogleSignIn() {
    const googleButtonDiv = this.googleButtonDiv.nativeElement;

    if (googleButtonDiv) {
      window.google.accounts.id.renderButton(
        googleButtonDiv,
        {
          theme: 'outline',
          type: 'standard',
          shape: 'pill',
          width: 344,
          text: 'signin_with',
          size: 'large',
          logo_alignment: 'center',
        }
      );
    }
  }

  private handleGoogleResponse(response: any) {
    const tenantId = this.tenantId();
    if (!tenantId) {
      return;
    }

    this.authService.googleLogin(tenantId, response.credential).subscribe();
  }
}
