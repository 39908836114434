
import {
  CdkOverlayOrigin, Overlay, OverlayConfig, OverlayModule, OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  Component,
  effect,
  ElementRef,
  EventEmitter,
  inject,
  input,
  Input,
  model,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import {
  LanguageService, User, Languages, AuthService,
} from '@instaclause-webapp/shared-core';
import { InitialsPipe, InlineSvgComponent } from '@instaclause-webapp/shared-ui';
import { TranslocoModule } from '@jsverse/transloco';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-menu',
  imports: [
    CommonModule,
    OverlayModule,
    InlineSvgComponent,
    TranslocoModule,
    InitialsPipe,
  ],
  templateUrl: './user-menu.component.html',
  styleUrl: './user-menu.component.scss',
  host: {
    // '#trigger': 'cdkOverlayOrigin',
  },
  hostDirectives: [ CdkOverlayOrigin ],
})
export class UserMenuComponent implements OnInit, OnDestroy {

  @ViewChild('menuTemplate') menuTemplate!: TemplateRef<any>;
  @Input() user!: User;
  @Output() menuClose = new EventEmitter();

  availableLanguages!: Languages[];
  overlayRef!: OverlayRef;
  languagesLength!: number;
  languageMenuOpen = false;
  isOpen = model<boolean>(false);
  origin = input.required<CdkOverlayOrigin>();

  protected languageService = inject(LanguageService);

  private authService = inject(AuthService);
  private overlay = inject(Overlay);
  private viewContainerRef = inject(ViewContainerRef);
  private elRef = inject(ElementRef);
  private subscription = new Subscription();


  constructor() {
    effect(() => {
      if (this.isOpen()) {
        // this.openMenu(this.trigger()!);
        this.openMenu();
      } else {
        this.closeMenu();
      }
    });
  }

  ngOnInit(): void {
    this.availableLanguages = this.languageService.getLanguages();
    this.languagesLength = this.availableLanguages.length;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openMenu(/* origin: CdkOverlayOrigin */) {
    console.log('>>>> triger: ', this.origin());
    if (this.overlayRef) {
      this.overlayRef.detach();
    }

    const positionStrategy = this.overlay.position()
      // .flexibleConnectedTo(origin.elementRef)
      .flexibleConnectedTo(this.origin().elementRef)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          offsetY: 20,
        },
      ]);

    const overlayConfig = new OverlayConfig({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });

    this.overlayRef = this.overlay.create(overlayConfig);
    const portal = new TemplatePortal(this.menuTemplate, this.viewContainerRef);
    this.overlayRef.attach(portal);

    this.subscription = this.overlayRef.backdropClick().subscribe(() => this.closeMenu());
  }

  closeMenu() {
    if (this.overlayRef) {
      this.menuClose.emit();
      this.overlayRef.detach();
    }
  }

  onChangeLanguageOpen() {
    this.languageMenuOpen = !this.languageMenuOpen;
  }

  onChangeLanguage(language: Languages): void {
    this.languageService.changeLanguage(language);
  }

  async logout() {
    await this.authService.logout();
  }
}
