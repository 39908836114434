<div class="bottom">
  <div class="bottom-container">
    <div class="bottom-utils">
      <app-ui-language [pagePosition]="'bottom'"></app-ui-language>
      <a class="bottom-link" [routerLink]="['./help']">
        <ui-button color="ghostOnLight" icon="help">
          {{ 'BOTTOM.HELP' | transloco }}
        </ui-button>
      </a>
    </div>
    <div class="bottom-actions">
      @if (isHome) {
        <ui-button color="ghostOnLight" icon="delete">{{ 'BOTTOM.TRASH' | transloco }}</ui-button>
        <a class="bottom-link" [routerLink]="['./contracts', 'new']">
          <ui-button color="ghostOnLight" icon="more">{{ 'BOTTOM.NEW_CONTRACT' | transloco }}</ui-button>
        </a>
      } @else {
        <ui-button color="ghostOnLight" icon="arrow-back" (click)="onBack()">{{ 'BOTTOM.BACK' | transloco }}</ui-button>
      }
    </div>
  </div>
</div>
