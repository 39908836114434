import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  input,
  model,
  output,
  signal,
  TemplateRef,
} from '@angular/core';

import { TranslocoModule } from '@jsverse/transloco';

import {
  Column, FilterQuery, TableConfig, TableData, TableFilter,
} from '../../../models/tabel.model';
import { HasValuePipe } from '../../../pipes/has-value.pipe';
import { ButtonComponent } from '../../button/button.component';
import { CheckboxComponent } from '../../checkbox/checkbox.component';
import { InlineSvgComponent } from '../../inline-svg/inline-svg.component';
import { TableFilterComponent } from '../table-filter/table-filter.component';

@Component({
  selector: 'ui-table',
  standalone: true,
  imports: [ CommonModule, TranslocoModule, ButtonComponent, CheckboxComponent,
    TableFilterComponent,
    InlineSvgComponent, HasValuePipe ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
  providers: [],
})
export class TableLayoutComponent {
  config = model<TableConfig<any>>();
  data = input<TableData<any>>([]);
  filter = input<TableFilter<Column>>();
  label = input<string | undefined>();
  template = input<TemplateRef<any>>();

  checkedValues = output<any>();
  triggerOpenOptions = output<any>();
  filterQuery = output<FilterQuery | undefined>();

  values = signal<number[]>([]);
  currentOption = signal<any>(undefined);
  previousOption = signal<any>(undefined);
  check = false;

  cssStyle = computed(() => {
    const config = this.config();
    const grid = config?.grid || `repeat(${config?.columns}, 1fr)`;
    const checkbox = config?.checkbox ? 'min-content ' : '';

    return {
      'grid-template-columns': `${checkbox}${grid} min-content`,
    };
  });

  onChangeUpdateCheckbox(data: any): void {
    if (this.values().includes(data)) {
      this.values.update((values) => values.filter((value) => value !== data));
    } else {
      this.values.update((values) => [ ...values, data ]);
    }
    this.checkedValues.emit(this.values());
  }

  onChangeCheckAll(event: boolean | string): void {
    this.check = event as boolean;
    const values = event ? this.data() : [];
    this.values.set(values);
    this.checkedValues.emit(values);
  }

  onClickOpenOptions(data: any): void {
    this.previousOption.set(this.currentOption());
    this.currentOption.set(this.currentOption() === data ? null : data);
    this.triggerOpenOptions.emit(this.currentOption());
  }

  triggerFilter(event?: FilterQuery): void {
    this.filterQuery.emit(event);
  }
}
