<div class="container">
  <app-menu class="menu"></app-menu>

  <div class="login">
    <div class="login-container">
      <div class="login-fields">
        <div class="login-fields-item">
          <div class="login-fields-item-label">{{ 'LOGIN.EMAIL' | transloco }}</div>
          <ui-email-field class="login-fields-item" [(value)]="loginModel.email"></ui-email-field>
        </div>

        <div class="login-fields-item">
          <div class="login-fields-item-label">{{ 'LOGIN.PASSWORD' | transloco }}</div>
          <ui-password-field class="login-fields-item" [(value)]="loginModel.password"></ui-password-field>
        </div>

      </div>

      <div class="login-actions">
        <div class="login-actions-item">
          <ui-checkbox class="login-actions-item-icon" [(value)]="rememberMe"></ui-checkbox>
          {{ 'LOGIN.REMEMBER' | transloco }}
        </div>
        <a class="login-actions-item link">{{ 'LOGIN.FORGOT' | transloco }}</a>
      </div>

      <div class="login-buttons">
        <ui-button class="login-buttons-button" (click)="login()">{{ 'LOGIN.TITLE' | transloco }}</ui-button>
        <shared-core-external-login />
      </div>
    </div>
  </div>

  <div class="bottom">
    <span class="bottom-text">{{ 'LOGIN.BOTTOM.TEXT1' | transloco }}</span>
    <a class="bottom-link">{{ 'LOGIN.BOTTOM.TEXT2' | transloco }}</a>
  </div>
</div>