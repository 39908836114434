import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslocoModule } from '@jsverse/transloco';

import { InlineSvgComponent } from '../../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-anchor-menu',
  imports: [ CommonModule, RouterModule, TranslocoModule, InlineSvgComponent ],
  templateUrl: './anchor-menu.component.html',
  styleUrl: './anchor-menu.component.scss',
  providers: [],
})
export class AnchorMenuComponent {
  menu = input<Record<string, string>[]>();
  anchorMenu = input<boolean>(true);
}
