<ul>
  @for (item of menu(); track $index) {
    @if (anchorMenu()) {
      <li>
        <a class="menu-item" [routerLink]="item['route']" [fragment]="item['fragment']">
          <ui-inline-svg [name]="item['icon']" class="menu-item-icon"></ui-inline-svg>
          <span class="menu-item-text">{{ item['text'] | transloco }}</span>
        </a>
      </li>
    } @else {
      <li>
        <a class="menu-item">
          <ui-inline-svg [name]="item['icon']" class="menu-item-icon"></ui-inline-svg>
          <span class="menu-item-text">{{ item['text'] | transloco }}</span>
        </a>
      </li>
    }
  }
</ul>
