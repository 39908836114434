<table class="table">
  @if (config()?.filter) {
  <ui-table-filter
    [config]="config()?.filter"
    [label]="label()"
    (filterQuery)="triggerFilter($event)"
    (checkAll)="onChangeCheckAll($event)"></ui-table-filter>
  }

  <tbody>
    @if (data().length > 0) { @for (item of data(); track $index) {
    <tr
      class="container color-{{ config()!.background }}"
      [ngStyle]="cssStyle()"
      [class.download]="config()!.extraItems | hasValue: item"
      [class.selected]="values() | hasValue: item">
      @if ( config()!.checkbox ) {
      <ui-checkbox
        class="container-checkbox"
        [value]="values() | hasValue: item"
        (valueChange)="onChangeUpdateCheckbox(item)"></ui-checkbox>
      }

      <ng-container
        [ngTemplateOutlet]="template()!"
        [ngTemplateOutletContext]="{ $implicit: item }">
      </ng-container>

      <button type="button" class="container-button" (click)="onClickOpenOptions(item)">
        <ui-inline-svg [name]="'more-horizontal'"></ui-inline-svg>
      </button>
    </tr>
    } } @else {
    <div class="table-empty">
      <h1 class="table-empty-title">{{ 'CONTRACT.LIST.EMPTY.TEXT1' | transloco }}</h1>
      <h1 class="table-empty-title">{{ 'CONTRACT.LIST.EMPTY.TEXT2' | transloco }}</h1>
      <ui-button size="large" color="secondaryOnLight">
        {{ 'CONTRACT.LIST.EMPTY.BUTTON' | transloco }}
      </ui-button>
    </div>
    }
  </tbody>
</table>
