import { CommonModule } from '@angular/common';
import { Component, input, model } from '@angular/core';

@Component({
  selector: 'ui-toggle',
  imports: [ CommonModule ],
  templateUrl: './toggle.component.html',
  styleUrl: './toggle.component.scss',
})
export class ToggleComponent {
  name = input<string>(`ui-toggle-${(Math.round((Math.random() * 100000000)).toString(16))}`);
  value = model<boolean>(false);

  toggleChecked() {
    this.value.set(!this.value());
  }
}
