import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  input,
  signal,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { firstValueFrom } from 'rxjs';

import { SvgService } from './svg.service';

@Component({
  selector: 'ui-inline-svg',
  imports: [ CommonModule ],
  template: '<div class="wrapper" [innerHTML]="svgIcon()"></div>',
  styleUrl: './inline-svg.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineSvgComponent {

  name = input<string>('');

  svgIcon = signal<SafeHtml | null>(null);

  constructor(private sanitizer: DomSanitizer, private svgService: SvgService) {
    effect(() => this.loadSvg(this.name()));
  }

  async loadSvg(name: string) {
    const svg$ = this.svgService.getSvg(name);

    if (!svg$) {
      return;
    }

    const svg = await firstValueFrom(svg$);

    this.svgIcon.set(this.sanitizer.bypassSecurityTrustHtml(svg));
  }
}
